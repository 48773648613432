'use strict';

module.exports = {
    sliceButtonText: function () {
        var $buttonWrapper = $('.button-wrapper .pd-check-availability .qf-btn-arrow, .textlink-wrapper .pd-check-availability .qf-btn');
        var $button = $('.pd-check-availability .qf-btn-arrow');

        if ($buttonWrapper.hasClass('qf-btn-arrow')) {
            for (let i = 0; i < $button.length; i++) {
                var buttonHtml = $button.eq([i]).html().split('<div');
                buttonHtml[0] = buttonHtml[0].replace(/\s\s+/g, ' ').trim();
                var lastWordIndex = buttonHtml[0].lastIndexOf(' ');
                var newButtonHtml = buttonHtml[0].slice(0, lastWordIndex) + ' <div class="d-inline-block">' + buttonHtml[0].slice(lastWordIndex + 1) + '<div' + buttonHtml[1] + '</div>';
                $button.eq([i]).html(newButtonHtml);
            }
        }
    },

    iframeRedirection: function () {
        var $iframeElem = $('.iframe-content');

        if ($iframeElem.length !== 0) {
            for (let i = 0; i < $iframeElem.length; i++) {
                var iframeURL = $iframeElem.eq([i]).attr('data-src');
                var iframeDomain = iframeURL.replace(/\.com\/.*/, '.com');

                window.addEventListener('message', (event) => { // eslint-disable-line no-loop-func
                    if (event.origin !== iframeDomain) {
                        return;
                    }
                    if (event.data === 'iFrame Loaded') {
                        document.querySelector('.wrapper-magiciframe iframe').contentWindow.postMessage('Resize Needed', iframeDomain);
                    } else if (event.data === 'iFrame Form Submitted') {
                        window.location.href = $('.wrapper-magiciframe').attr('data-redirect');
                    } else if (event.data.title === 'iFrame Resized') {
                        document.querySelector('.wrapper-magiciframe iframe').style.height = event.data.height + 'px';
                    }
                }, false);
            }
        }
    },

    initTooltip: function () {
        var $tooltipElem = $('.highlight-benefit[data-toggle="tooltip"]');

        if ($tooltipElem.length !== 0) {
            $tooltipElem.tooltip();
        }
    }
};
